export function getDocumentPosition(node) {
  const box = node.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {
    top: box.top + scrollTop,
    left: box.left + scrollLeft,
    right: box.right + scrollLeft,
    bottom: box.bottom + scrollTop,
  };
}
