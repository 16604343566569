import RailsUJS from "@rails/ujs";
if (!window._rails_loaded) RailsUJS.start();

import "@hotwired/turbo-rails";

// Import and setup alpine
import Alpine from "alpinejs";
// import "alpine-turbo-drive-adapter";
import clipboard from "../components/clipboard";
import combobox from "../components/combobox";
import datePicker from "../components/date_picker";
import dateRangeSelect from "../components/date_range_select";
import dropdown from "../components/dropdown";
import graph from "../components/graph";
import header from "../components/header";
import lineChart from "../components/line_chart";
import personPreview from "../components/person_preview";
import popup from "../components/popup";
import realtimeForm from "../components/realtime_form";
import select from "../components/select";
import { SwisstopoMap } from "../components/swisstopo";

import {
  enableFlexGapPolyfill,
  enableFrameScroll,
  enablePreserveScroll,
  enableTurboLogger,
  scrollToId,
} from "../lib";

SwisstopoMap.register();

window.Alpine = Alpine;
Alpine.data("combobox", combobox);
Alpine.data("datePicker", datePicker);
Alpine.data("dateRangeSelect", dateRangeSelect);
Alpine.data("dropdown", dropdown);
Alpine.data("graph", graph);
Alpine.data("header", header);
Alpine.data("lineChart", lineChart);
Alpine.data("personPreview", personPreview);
Alpine.data("popup", popup);
Alpine.data("realtimeForm", realtimeForm);
Alpine.data("select", select);
Alpine.data("clipboard", clipboard);

document.addEventListener("DOMContentLoaded", () => {
  if (import.meta.env.DEV) enableTurboLogger();

  window.smartmonitor = {};
  enableFlexGapPolyfill();
  enablePreserveScroll();
  enableFrameScroll();
  Alpine.start();
});

document.addEventListener("alpine:init", () => {
  Alpine.store("banner", {
    hidden: false,

    hide() {
      this.hidden = true;
    },
  });
});

/* Scroll to id of form if relevant */
document.addEventListener("turbo:submit-end", (event) => {
  const hash = event.detail.formSubmission.location.hash;

  /* Only continue if the location has a hash
   * We only scroll a tiny bit after the render has completed
   * to be sure that the relevant element *should* be on the page
   * and Turbo is completely finished
   */
  if (hash) {
    document.addEventListener(
      "turbo:render",
      () => {
        setTimeout(() => {
          scrollToId(hash);
        }, 250);
      },
      { once: true },
    );
  }
});
