import { getDocumentPosition } from "../lib/document_position";

export default function personPreview() {
  return {
    currentPerson: null,
    triggerNode: null,
    value: null,
    opacity: 0,
    top: 0,
    left: 0,
    setterTimeout: null,

    init() {
      const people = this.$el.querySelectorAll("[data-person-id]");
      people.forEach((p) => {
        p.addEventListener("mouseenter", (e) => this.setCurrentPerson(e));
        p.addEventListener("click", (e) => this.setCurrentPerson(e, true));
      });
      this.$watch("currentPerson", () => this.updatePreview());
      this.$refs.previewFrame.addEventListener("turbo:frame-render", () => {
        this.correctPreviewPosition();
        this.opacity = 1;
        this.setValue();
      });
      this.$root.addEventListener("mouseleave", () => {
        this.currentPerson = null;
        clearTimeout(this.setterTimeout);
      });
    },

    setCurrentPerson(event, immediate = false) {
      const person_node = event.target;
      if (this.setterTimeout) clearTimeout(this.setterTimeout);
      const id = person_node.getAttribute("data-person-id");
      const value = person_node.getAttribute("data-person-value");
      this.setterTimeout = setTimeout(
        () => {
          this.triggerNode = person_node;
          this.currentPerson = id;
          this.value = value;
        },
        immediate ? 0 : 100,
      );
    },

    updatePreview() {
      if (this.currentPerson === null) return;

      this.opacity = 0;
      const { bottom, right } = getDocumentPosition(this.triggerNode);
      this.left = `${right + 5}px`;
      this.top = `${bottom + 5}px`;
    },

    get src() {
      return this.currentPerson
        ? this.$refs.previewFrame
            .getAttribute("href")
            .replace("PERSON_ID", this.currentPerson)
        : "";
    },

    correctPreviewPosition() {
      if (this.currentPerson === null) return;

      const { top, left } = getDocumentPosition(this.triggerNode);
      const box = this.$refs.previewFrame.getBoundingClientRect();
      if (box.x + box.width > window.innerWidth) {
        this.left = `${left - box.width - 5}px`;
      }

      if (box.y + box.height > window.innerHeight) {
        this.top = `${top - box.height - 5}px`;
      }
    },

    setValue() {
      if (this.value) {
        this.$refs.previewFrame.querySelector(
          "[data-preview-target=value]",
        ).innerText = this.value;
      }
    },
  };
}
