import {
  dateFromISO,
  dateToISO,
  setEndOfMonth,
  setToStart,
  shiftMonth,
} from "../lib";
import { Calender } from "../lib";

export default function datePicker({ min }) {
  const today = setToStart(new Date());
  const lowerLimit = dateFromISO(min);
  const upperLimit = setEndOfMonth(today);

  return {
    selectedStart: null,
    selectedEnd: null,
    activeStart: null,
    activeEnd: null,

    leftCalender: new Calender(shiftMonth(new Date(), -1), {
      lower: lowerLimit,
      upper: shiftMonth(upperLimit, -1),
    }),
    rightCalender: new Calender(new Date(), {
      lower: shiftMonth(lowerLimit, 1),
      upper: upperLimit,
    }),

    init() {
      // Make sure the two months never overlap
      this.$watch("leftCalender.unix", (unix) => {
        if (unix >= this.rightCalender.unix) this.rightCalender.goToNext();
      });

      this.$watch("rightCalender.unix", (unix) => {
        if (unix <= this.leftCalender.unix) this.leftCalender.goToPrev();
      });
    },

    set selectedRange({ startDate, endDate }) {
      this.selectedStart = dateFromISO(startDate);
      this.selectedEnd = dateFromISO(endDate);

      // Set the custom range views
      this.leftCalender.jumpTo(this.selectedStart);
      this.rightCalender.jumpTo(this.selectedEnd);
    },

    get hasRange() {
      const hasStart = this.selectedStart !== null;
      const hasActiveRange =
        this.activeEnd !== null &&
        this.activeEnd.getTime() !== this.activeStart?.getTime();
      const hasSelectedRange =
        this.selectedEnd?.getTime() !== this.selectedStart?.getTime();
      return hasStart && (hasActiveRange || hasSelectedRange);
    },

    generateDays(month) {
      const startUnix = this.selectedStart?.getTime();
      const endUnix =
        this.activeEnd &&
        this.activeStart?.getTime() !== this.activeEnd?.getTime()
          ? this.activeEnd.getTime()
          : this.selectedEnd?.getTime();
      return month.days.map((day) => {
        let isSelected = false;
        if (startUnix === day.unix) isSelected = "start";
        else if (endUnix === day.unix) isSelected = "end";
        const isInRange =
          this.hasRange && day.unix > startUnix && day.unix < endUnix;

        return {
          ...day,
          isSelected,
          isInRange,
        };
      });
    },

    set selectedDate(date) {
      const newDate = new Date(date);
      const startUnix = this.selectedStart?.getTime();
      if (
        this.selectedStart === null ||
        this.selectedEnd?.getTime() !== startUnix ||
        startUnix > date
      ) {
        this.selectedStart = newDate;
      }

      this.selectedEnd = newDate;
      this.unsetActiveDate();
      this.$dispatch("dates-changed", {
        startDate: dateToISO(this.selectedStart),
        endDate: dateToISO(this.selectedEnd),
      });
    },

    set activeDate(date) {
      const newDate = new Date(date);
      const startUnix = this.selectedStart?.getTime();
      if (
        this.selectedStart === null ||
        this.selectedEnd?.getTime() !== startUnix ||
        startUnix > date
      ) {
        this.activeStart = newDate;
      }

      this.activeEnd = newDate;
    },

    unsetActiveDate() {
      this.activeEnd = null;
      this.activeStart = null;
    },
  };
}
