import { shiftMonth, shiftYear, getDaysInMonth } from "./dates";

const WEEKSTART = 1; // The week starts on monday
// The date boundary represents the max difference from the Unix epoch which will still generw]ate a valid date
// This value should technically be 9,007,199,254,740,992 but that return Invalid Date's in some browsersxs
const DATE_BOUNDARY = 8_640_000_000_000_000;

/**
 * A CalenderMonth represent a month and its days in a calender
 *
 * It keeps track of its current month and date and generates the days for a calender, and allows easy switching to the prev or next month
 */
export class Calender {
  constructor(date = new Date(), limits = {}, mode = "days") {
    // `this._trackingDate` holds a date object for the current month. It is forced to always be the first of the month
    // This should actually be a private fields, but we can't use those when we wrap this in a proxy
    let newDate = new Date(date.getTime());
    newDate.setDate(1);
    this._trackingDate = newDate;

    // Set limits
    // We store these as unix-timestamps, since we only need those in comparison
    this.lowerLimit = limits.lower.getTime() || -DATE_BOUNDARY;
    this.upperLimit = limits.upper?.getTime() || DATE_BOUNDARY;

    // Mode to generate the correct label
    this.mode = mode;
  }

  /**
   * Write out the current month and year
   */
  get label() {
    if (this.mode === "months") return this.currentYear;

    const lang = document.documentElement.lang;
    const format = new Intl.DateTimeFormat(lang, { month: "long" });
    return `${format.format(this._trackingDate)} ${this.currentYear}`;
  }

  // Switch months / years
  get next() {
    return this.mode === "days"
      ? shiftMonth(this._trackingDate, 1)
      : shiftYear(this._trackingDate, 1);
  }

  get hasNext() {
    return this.next.getTime() < this.upperLimit;
  }

  get prev() {
    return this.mode === "days"
      ? shiftMonth(this._trackingDate, -1)
      : shiftYear(this._trackingDate, -1);
  }

  get hasPrev() {
    return this.prev.getTime() > this.lowerLimit;
  }

  goToNext() {
    if (!this.hasNext) return;

    this._trackingDate = this.next;
    this._trackingDate.setDate(1);
    if (this.mode === "months") this._trackingDate.setMonth(0);
  }

  goToPrev() {
    if (!this.hasPrev) return;

    this._trackingDate = this.prev;
    this._trackingDate.setDate(1);
    if (this.mode === "months") this._trackingDate.setMonth(0);
  }

  jumpTo(date) {
    const newDate = new Date(date.getTime());
    newDate.setDate(1);
    this._trackingDate = newDate;
  }

  // Map some of Date's method to our internal tracking date
  get currentYear() {
    return this._trackingDate.getFullYear();
  }

  get currentMonth() {
    return this._trackingDate.getMonth();
  }

  get unix() {
    return this._trackingDate.getTime();
  }

  get calenderStart() {
    let start = this._trackingDate.getDay() - WEEKSTART;
    while (start < 0) start += 7;
    return start;
  }

  /**
   * The number of days in the current month's view
   */
  get nrOfDays() {
    let count = getDaysInMonth(this._trackingDate) + this.calenderStart;
    while (count % 7) count += 1;
    return count;
  }

  /**
   * Return all the days in the current month's view
   *
   * Each day is label with some helpers to use when rendering
   */
  get days() {
    let days = [];
    for (let i = 0; i < this.nrOfDays; i++) {
      const day = new Date(
        this.currentYear,
        this.currentMonth,
        1 + i - this.calenderStart,
      );
      const unix = day.getTime();
      const dayMonth = day.getMonth();

      const isPrevMonth = dayMonth < this.currentMonth;
      const isNextMonth = dayMonth > this.currentMonth;
      const isThisMonth = !isPrevMonth && !isNextMonth;
      const isToday = unix === new Date().getTime();
      const isDisabled = unix < this.lowerLimit || unix > this.upperLimit;

      days.push({
        day,
        unix,
        label: day.getDate(),
        isPrevMonth,
        isNextMonth,
        isThisMonth,
        isToday,
        isDisabled,
      });
    }
    return days;
  }

  /**
   * Return all the months in the current year's view
   *
   * Each day is label with some helpers to use when rendering
   */
  get months() {
    const lang = document.documentElement.lang;
    const format = new Intl.DateTimeFormat(lang, { month: "short" });
    let months = [];
    for (let i = 0; i < 12; i++) {
      const month = new Date(this.currentYear, i, 1);
      const unix = month.getTime();
      const label = format.format(month);
      const isDisabled = unix < this.lowerLimit || unix > this.upperLimit;

      months.push({ month, label, unix, isDisabled });
    }
    return months;
  }

  /**
   * Return all the year in the calender's range
   *
   * Each year is label with some helpers to use when rendering
   */
  get years() {
    let years = [];
    let date = new Date(this.lowerLimit);
    date.setMonth(0);
    date.setDate(1);
    do {
      const unix = date.getTime();
      const year = new Date(unix);
      const label = year.getFullYear();

      years.push({ year, label, unix });
      date = shiftYear(date, 1);
    } while (date.getTime() < this.upperLimit);
    return years;
  }
}
