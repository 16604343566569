// Refs: startInput, endInput
import { setupActiveState, hasOptions, setupSelectedState } from "./concerns";

export default function dateRangeSelect({
  activeClass = "date-range__option--active",
  selectedClass = "date-range__option--selected",
  openClass = "date-range__wrapper--open",
  openPickerClass = "date-range__wrapper--picker",
} = {}) {
  return {
    expanded: false,
    showDatePicker: false,
    startDate: null,
    endDate: null,
    ...hasOptions(),
    ...setupActiveState(activeClass),
    ...setupSelectedState(selectedClass),
    openClasses: Array.isArray(openClass) ? openClass : [openClass],
    openPickerClasses: Array.isArray(openPickerClass)
      ? openPickerClass
      : [openPickerClass],

    init() {
      const start = this.$root.querySelector("[x-model=startDate]").value;
      const end = this.$root.querySelector("[x-model=endDate]").value;
      if (start && end) {
        this.startDate = start.slice(0, 10);
        this.endDate = end.slice(0, 10);
        // We have to send this event in a timeout to make sure the datePicker component is also fully initialized
        setTimeout(() => {
          this.sendToDatePicker();
        }, 500);
      }
      // Style element based on selectedIndex and activeIndex
      // We need to setup these watchers before initializing the options
      this.activeStateInit();
      this.selectedStateInit();

      // Find all options and add event listeners
      this.optionsInit();

      // Reset the activeElement when collapsing
      this.$watch("expanded", () => !this.expanded && (this.activeIndex = -1));
      // Reset show custom range when collapsing
      this.$watch(
        "expanded",
        () => !this.expanded && (this.showDatePicker = false),
      );
    },

    get showPlaceholder() {
      return this.startDate === null || this.endDate === null;
    },

    setNewRangeFromPreset() {
      const option = this.options[this.selectedIndex];
      if (option) {
        this.startDate = option.getAttribute("data-date-range-start");
        this.endDate = option.getAttribute("data-date-range-end");
        this.sendToDatePicker();
      }
    },

    handleCustomRange($event) {
      const { startDate, endDate } = $event.detail;
      this.startDate = startDate;
      this.endDate = endDate;
      this.$nextTick(() => {
        this.$dispatch("programmatic-input");
      });
    },

    onOptionClick() {
      if (this.activeIndex === -1) return;

      const option = this.options[this.activeIndex];
      if (option.hasAttribute("data-date-range-custom")) {
        this.showDatePicker = true;
      } else {
        this.selectedIndex = this.activeIndex;
        this.setNewRangeFromPreset();
        this.expanded = !this.expanded;
      }
    },

    sendToDatePicker() {
      this.$root
        .querySelector("[data-date-range-target='datePicker']")
        .dispatchEvent(
          new CustomEvent("set-dates", {
            detail: {
              startDate: this.startDate,
              endDate: this.endDate,
            },
          }),
        );
    },

    get writtenStartDate() {
      return this.startDate && this.startDate.split("-").join(".");
    },

    get writtenEndDate() {
      return this.endDate && this.endDate.split("-").join(".");
    },

    target: {
      ["x-show"]() {
        return this.expanded;
      },
      ["x-transition:enter"]() {
        return "transition ease-out duration-100";
      },
      ["x-transition:enter-start"]() {
        return "transform opacity-0 scale-95";
      },
      ["x-transition:enter-end"]() {
        return "transform opacity-100 scale-100";
      },
      ["x-transition:leave"]() {
        return "transition ease-in duration-75";
      },
      ["x-transition:leave-start"]() {
        return "transform opacity-100 scale-100";
      },
      ["x-transition:leave-end"]() {
        return "transform opacity-0 scale-95";
      },
    },

    trigger: {
      ["@click.prevent.stop"]() {
        this.expanded = !this.expanded;
      },
      ["@keyup.esc.prevent.stop"]() {
        this.expanded = false;
      },
      ["@keyup.enter.prevent.stop"]() {
        this.setSelectedOption();
      },
      ["@keydown.arrow-up.prevent"]() {
        // Prevent scroll event
      },
      ["@keydown.arrow-down.prevent"]() {
        // Prevent scroll event
      },
      ["@keyup.arrow-up.prevent"]() {
        this.findNextActive("up");
      },
      ["@keyup.arrow-down.prevent"]() {
        this.findNextActive("down");
      },
    },

    wrapper: {
      [":data-dirty"]() {
        return this.startDate && this.endDate;
      },
      [":class"]() {
        let classes = [];
        if (this.expanded) classes = [...classes, ...this.openClasses];
        if (this.showDatePicker) {
          classes = [...classes, ...this.openPickerClasses];
        }
        return classes.join(" ");
      },
      ["@click.outside"]() {
        this.expanded = false;
      },
    },
  };
}
