export default function clipboard() {
  return {
    copied: false,

    handleClick(event) {
      event.preventDefault();

      window.navigator.clipboard.writeText(this.text);
      this.copied = true;
      setTimeout(() => (this.copied = false), 1500);
    },

    get text() {
      return this.$refs.text.innerText;
    },
  };
}
