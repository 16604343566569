import { scrollIntoViewIfNeeded } from "../lib";
import { setupActiveState, hasOptions, setupSelectedState } from "./concerns";

export default function combobox({
  activeClass = "combobox__option--active",
  selectedClass = "combobox__option--selected",
} = {}) {
  return {
    expanded: false,
    query: "",
    ...hasOptions(),
    ...setupActiveState(activeClass),
    ...setupSelectedState(selectedClass, true),

    init() {
      // Fill selectedValues from fallback select
      [...this.$refs.field.selectedOptions].forEach(
        (opt) => (this.selectedValues[opt.value] = opt.textContent),
      );

      // Style element based on selectedIndex and activeIndex
      // We need to setup these watchers before initializing the options
      this.activeStateInit();
      this.selectedStateInit();

      // Find all options and add event listeners
      this.optionsInit((option, index) => {
        if (this.selectedValues[option.getAttribute("data-value")]) {
          this.selectedIndexes.push(index);
        }
      });

      // Reset the activeElement when collapsing
      this.$watch("expanded", () => !this.expanded && (this.activeIndex = -1));

      // Make sure options are in view after opening
      this.$watch("expanded", () => {
        if (this.expanded) {
          this.$nextTick(() =>
            scrollIntoViewIfNeeded(this.$refs.scrollWrapper),
          );
        }
      });

      // Filter options based on the query
      this.$watch("query", () => this.filterOptions());

      // Hide the fallback field
      this.$refs.field.hidden = true;
    },

    onOptionClick() {
      this.toggleSelectedOption();
    },

    toggleSelectedOption() {
      if (this.activeIndex !== -1) {
        this.selectedIndexes.includes(this.activeIndex)
          ? this.removeSelectedOption()
          : this.addSelectedOption();
      }

      this.expanded = !this.expanded;
    },

    addSelectedOption() {
      let newValue = this.options[this.activeIndex].getAttribute("data-value");
      let newLabel = this.options[this.activeIndex].getAttribute("data-label");

      if (newValue === "new") {
        // if new value, create an option in fallback and mark it as selected
        let newOption = document.createElement("option");
        newValue = this.query;
        newLabel = this.query;
        newOption.value = newValue;
        newOption.setAttribute("selected", "selected");
        this.$refs.field.appendChild(newOption);
      } else {
        // if existing value, find the option and mark it as selected
        this.selectedIndexes.push(this.activeIndex);
        this.$refs.field
          .querySelector(`[value="${newValue}"]`)
          .setAttribute("selected", "selected");
      }
      // Add value to list
      this.selectedValues[newValue] = newLabel;
      // Reset query
      this.query = "";
    },

    removeSelectedOption(value = null) {
      // If we pass a value, get the index in the options list of that value
      if (value) {
        this.activeIndex = [...this.options].findIndex(
          (o) => o.getAttribute("data-value") === value,
        );
      }

      // Remove from list of selectedIndexes
      const index = this.selectedIndexes.findIndex(
        (opt) => opt === this.activeIndex,
      );
      if (index !== -1) this.selectedIndexes.splice(index, 1);

      // Find value of option if not present
      if (!value) {
        value = this.options[this.activeIndex].getAttribute("data-value");
      }

      // Deselect option
      this.$refs.field
        .querySelector(`[value="${value}"]`)
        .removeAttribute("selected");

      // Remove option from list of selectedValues
      delete this.selectedValues[value];
    },

    get badgeValues() {
      return Object.entries(this.selectedValues);
    },

    trigger: {
      ["@click.prevent"]() {
        this.expanded = !this.expanded;
      },
      ["@keyup.esc.prevent.stop"]() {
        this.expanded = false;
      },
      ["@keyup.enter.prevent.stop"]() {
        this.toggleSelectedOption();
      },
      ["@keydown.arrow-up.prevent"]() {
        this.findNextActive("up");
      },
      ["@keydown.arrow-down.prevent"]() {
        this.findNextActive("down");
      },
      ["@input"]($event) {
        this.expanded = true;
        this.query = $event.target.value;
      },
    },

    placeholder: {
      ["x-show"]() {
        return this.selectedIndexes.length === 0;
      },
    },

    target: {
      ["x-show"]() {
        return this.expanded;
      },
      ["x-transition:enter"]() {
        return "transition ease-out duration-100";
      },
      ["x-transition:enter-start"]() {
        return "transform opacity-0 scale-95";
      },
      ["x-transition:enter-end"]() {
        return "transform opacity-100 scale-100";
      },
      ["x-transition:leave"]() {
        return "transition ease-in duration-75";
      },
      ["x-transition:leave-start"]() {
        return "transform opacity-100 scale-100";
      },
      ["x-transition:leave-end"]() {
        return "transform opacity-0 scale-95";
      },
    },
  };
}
