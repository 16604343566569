export function shiftMonth(date, shift) {
  let newMonth = new Date(date.getTime());
  // If the date is bigger than the number of days in the target month, JS will skip to a month later
  // So we have to set the date to the first to be sure
  newMonth.setDate(1);
  newMonth.setMonth(newMonth.getMonth() + shift);
  if (shift < 0) newMonth.setDate(getDaysInMonth(newMonth));
  return newMonth;
}

export function shiftYear(date, shift) {
  let newYear = new Date(date.getTime());
  // If the date is bigger than the number of days in the target month, JS will skip to a month later
  // So we have to set the date to the first to be sure
  newYear.setMonth(0);
  newYear.setDate(1);
  newYear.setYear(newYear.getFullYear() + shift);
  if (shift < 0) {
    newYear.setDate(31);
    newYear.setMonth(11);
  }
  return newYear;
}

export function isLeapYear(year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

export function getDaysInMonth(year, month) {
  if (year instanceof Date) {
    month = year.getMonth();
    year = year.getFullYear();
  }

  return [
    31,
    isLeapYear(year) ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ][month];
}

export function setToStart(date) {
  date.setHours(0, 0, 0, 0);
  return date;
}

export function setEndOfMonth(date) {
  let newDate = new Date(date.getTime());
  newDate.setDate(getDaysInMonth(date));
  newDate.setHours(23, 59, 59, 999);
  return newDate;
}

export function dateToISO(date) {
  if (!date) return;

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function dateFromISO(string) {
  return new Date(...string.split("-"));
}
