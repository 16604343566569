export default function lineChart() {
  return {
    activePeriod: null,
    left: 0,
    top: 0,

    init() {
      /* We don't use x-show to show/hide the dialogs, since its performance in safari is severly lacking */
      this.$watch("activePeriod", (nv) => {
        this.$root
          .querySelectorAll(
            `[data-graph-dialog-target]:not([data-graph-dialog-target="${nv}"])`,
          )
          .forEach((el) => {
            el.hidden = true;
          });
        if (
          nv !== null &&
          this.$root.querySelector(`[data-graph-dialog-target="${nv}"]`)
        ) {
          this.$root.querySelector(
            `[data-graph-dialog-target="${nv}"]`,
          ).hidden = false;
        }
      });
      this.$root.querySelectorAll("[data-dialog-trigger]").forEach((el) => {
        el.addEventListener("mouseover", (e) => {
          this.activePeriod = el.getAttribute("data-dialog-trigger");
          this.determineLocation(e);
        });
        el.addEventListener("mousemove", (e) => {
          this.determineLocation(e);
        });
        el.addEventListener("click", (e) => {
          this.activePeriod = el.getAttribute("data-dialog-trigger");
          this.determineLocation(e);
        });
        el.addEventListener("mouseleave", () => {
          this.activePeriod = null;
        });
      });
    },

    determineLocation(event) {
      const box = this.$refs.dialogContainer.getBoundingClientRect();
      let left = event.pageX + 5;
      let top = event.pageY + 5;
      if (left + box.width > window.innerWidth) left = left - box.width - 10;
      if (top + box.height - window.scrollY > window.innerHeight) {
        top = top - box.height - 10;
      }
      this.left = `${left}px`;
      this.top = `${top}px`;
    },

    handleClickOutside(e) {
      // Don't trigger dialog if user clicked on trigger zone
      if (e.target.hasAttribute("data-dialog-trigger")) return;

      this.activePeriod = null;
    },
  };
}
