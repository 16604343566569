import { scrollIntoViewIfNeeded } from "../lib";
import { setupActiveState, hasOptions, setupSelectedState } from "./concerns";

export default function select({
  activeClass = "select__option--active",
  selectedClass = "select__option--selected",
} = {}) {
  return {
    expanded: false,
    groups: [],
    ...hasOptions(),
    ...setupActiveState(activeClass),
    ...setupSelectedState(selectedClass),

    init() {
      const value = this.$refs.field.value;

      // Style element based on selectedIndex and activeIndex
      // We need to setup these watchers before initializing the options
      this.activeStateInit();
      this.selectedStateInit(() => {
        this.$refs.field.value =
          this.options[this.selectedIndex]?.dataset.value;
      });

      this.optionsInit((option, index) => {
        if (option.getAttribute("data-value") === value) {
          this.selectedIndex = index;
        }
      });

      // Reset the activeElement when collapsing
      this.$watch("expanded", () => !this.expanded && (this.activeIndex = -1));

      // Make sure options are in view when opened
      this.$watch("expanded", () => {
        if (this.expanded) {
          this.$nextTick(() =>
            scrollIntoViewIfNeeded(
              this.$root.querySelector("[x-bind='target']"),
            ),
          );
        }
      });

      // Hide the fallback field
      this.$refs.field.hidden = true;
    },

    get displayValue() {
      const selectedOption = this.options[this.selectedIndex];
      return selectedOption ? selectedOption.textContent : "";
    },

    onOptionClick() {
      this.setSelectedOption();
    },

    trigger: {
      ["@click.prevent.stop"]() {
        this.expanded = !this.expanded;
      },
      ["@keyup.esc.prevent.stop"]() {
        this.expanded = false;
      },
      ["@keyup.enter.prevent.stop"]() {
        this.setSelectedOption();
      },
      ["@keydown.arrow-up.prevent"]() {
        this.findNextActive("up");
      },
      ["@keydown.arrow-down.prevent"]() {
        this.findNextActive("down");
      },
    },

    target: {
      ["x-show"]() {
        return this.expanded;
      },
      ["x-transition:enter"]() {
        return "transition ease-out duration-100";
      },
      ["x-transition:enter-start"]() {
        return "transform opacity-0 scale-95";
      },
      ["x-transition:enter-end"]() {
        return "transform opacity-100 scale-100";
      },
      ["x-transition:leave"]() {
        return "transition ease-in duration-75";
      },
      ["x-transition:leave-start"]() {
        return "transform opacity-100 scale-100";
      },
      ["x-transition:leave-end"]() {
        return "transform opacity-0 scale-95";
      },
    },
  };
}
