export default function popup({ showAfter = 1, dontShowFor = 24 }) {
  return {
    show: false,
    dismissedAt: null,

    init() {
      if (window.location.search.includes("showPopup")) this.show = true;
      const resetPopup = window.location.search.includes("resetPopup");

      this.dismissedAt =
        localStorage.getItem("smartmonitor:popup:dismissedAt") || null;

      if (resetPopup === false && this.dismissedWithinRange) return;
      this.schedulePopup();
    },

    schedulePopup() {
      setTimeout(() => {
        this.show = true;
      }, showAfter * 1000);
    },

    dismiss() {
      this.show = false;
      this.updateLocalStorage();
    },

    updateLocalStorage() {
      localStorage.setItem("smartmonitor:popup:dismissedAt", new Date());
    },

    get dismissedWithinRange() {
      let rangeBoundary = new Date();
      rangeBoundary.setHours(rangeBoundary.getHours() - (dontShowFor % 24));
      rangeBoundary.setDate(
        rangeBoundary.getDate() - Math.floor(dontShowFor / 24),
      );
      return (
        this.dismissedAt !== null && rangeBoundary < new Date(this.dismissedAt)
      );
    },
  };
}
