export default function header() {
  return {
    expanded: false,
    init() {
      this.$watch("expanded", (newValue) => {
        if (newValue) {
          document.body.classList.add("--prevent-scrolling-xs-sm-only");
        } else {
          document.body.classList.remove("--prevent-scrolling-xs-sm-only");
        }
      });
    },
  };
}
