import { Turbo } from "@hotwired/turbo-rails";

export default function realtimeForm() {
  return {
    src: "",
    action: "",
    form: null,
    addlFilters: [],

    init() {
      this.form = this.$root.querySelector("form");
      this.action = this.form?.action;
    },

    toggleFilter(name) {
      const index = this.addlFilters.findIndex((filter) => filter === name);
      if (index === -1) this.addlFilters.push(name);
      else this.addlFilters.splice(index, 1);

      this.onInput();
    },

    onInput() {
      const formData = new FormData(this.form);
      for (const f of this.addlFilters) formData.append("q[include][]", f);

      const search = new URLSearchParams(formData);
      this.src = `${this.action.split("#")[0]}?${search.toString()}`;
      // Manually set next state in history
      Turbo.session.visit(this.src, { action: "replace", willRender: false });
    },
  };
}
