import { getArrayFromParams } from "../lib/params";
import { scrollToRight } from "../lib/scroll";

export default function graph({
  mode = "graph",
  baseParam = "g1",
  scrollTableRight = false,
  scrollVizRight = true,
} = {}) {
  const modeParam = `${baseParam}[mode]`;
  const activePartiesParam = `${baseParam}[active_parties]`;
  return {
    graphMode: mode !== "table",
    modeParam,
    activeParties: [],
    activePartiesParam,
    zoomedIn: false,

    init() {
      // Setup watchers
      this.$watch("activeParties", () => {
        this.filterTable();
        this.filterViz();
        this.updateFrameableCode();
      });
      this.$watch("graphMode", () => {
        this.updatePeriodOptions();
        this.updateFrameableCode();
      });

      // Get active parties from url
      this.activeParties = getArrayFromParams(this.activePartiesParam).map(
        (i) => +i,
      );

      // Setup callbacks for frame renders
      this.$root.addEventListener("turbo:frame-render", (e) => {
        // Don't trigger these events when `frame-renders` happens for the person preview
        if (e.target !== this.$root) return;

        this.filterTable();
        this.filterViz();
        this.updateFrameableCode();
        if (this.zoomedIn) this.zoom(null, true);
        // Scroll to end of table or viz
        if (scrollTableRight) {
          scrollToRight(this.$el.querySelector("[data-graph-scroll=table]"));
        }

        if (scrollVizRight) {
          scrollToRight(this.$el.querySelector("[data-graph-scroll=viz]"));
        }
      });
      // Scroll to end of table
      if (scrollTableRight) {
        scrollToRight(this.$el.querySelector("[data-graph-scroll=table]"));
      }

      if (scrollVizRight) {
        scrollToRight(this.$el.querySelector("[data-graph-scroll=viz]"));
      }
    },

    setGraphMode() {
      this.graphMode = true;
    },

    setTableMode() {
      this.graphMode = false;
    },

    toggleParty(e) {
      let id = +(e.target.hasAttribute("data-party-id")
        ? e.target.getAttribute("data-party-id")
        : e.target.parentElement.getAttribute("data-party-id"));
      const i = this.activeParties.findIndex((p) => p === id);
      i > -1 ? this.activeParties.splice(i, 1) : this.activeParties.push(id);
    },

    filterTable() {
      const table = this.$el.querySelector("[data-graph-target=table]");
      const els = table.querySelectorAll("[data-party-id]");
      if (this.activeParties.length === 0) {
        els.forEach((el) => (el.hidden = false));
      } else {
        els.forEach(
          (el) =>
            (el.hidden = !this.activeParties.includes(
              +el.getAttribute("data-party-id"),
            )),
        );
      }
    },

    filterViz() {
      const viz = this.$el.querySelector("[data-graph-target=viz]");
      const els = viz.querySelectorAll("[data-party-id]");
      if (this.activeParties.length === 0) {
        els.forEach((el) => el.classList.remove("viz-item--hidden"));
      } else {
        els.forEach((el) => {
          const party = +el.getAttribute("data-party-id");
          const hidden = !this.activeParties.includes(party);
          hidden
            ? el.classList.add("viz-item--hidden")
            : el.classList.remove("viz-item--hidden");
        });
      }
      this.setDotPlotPositions();
    },

    setDotPlotPositions() {
      const viz = this.$el.querySelector("[data-graph-target=viz]");
      const bins = viz.querySelectorAll(".dot-plot__bin");
      bins.forEach((bin) => {
        const lowestPos = bin.querySelector("circle").getAttribute("cy");
        const dots = bin.querySelectorAll("circle:not(.viz-item--hidden)");
        dots.forEach((dot, i) => dot.setAttribute("cy", lowestPos - 10 * i));
      });
    },

    updatePeriodOptions() {
      const options = this.$el.querySelectorAll(
        "[data-graph-target=periodOption]",
      );
      options.forEach((option) => {
        const url = option.getAttribute("href").split("?");
        const params = new URLSearchParams(url[1]);
        params.set(this.modeParam, this.graphMode ? "graph" : "table");
        option.setAttribute("href", `${url[0]}?${params.toString()}`);
      });
    },

    updateFrameableCode() {
      const target = this.$root.querySelector(
        "[data-graph-target=frameable-code]",
      );
      if (!target) return;

      const url = target.getAttribute("data-frameable-url").split("?");
      const params = new URLSearchParams(url[1]);
      params.set(this.modeParam, this.graphMode ? "graph" : "table");
      params.set(this.activePartiesParam, this.activeParties);
      const newURL = `${url[0]}?${params.toString()}`;
      target.setAttribute("data-frameable-url", newURL);

      const template = target.getAttribute("data-frameable-template");
      target.innerText = template.replace("FRAMEABLE_URL", newURL);
    },

    zoom(_, forceZoom = false) {
      // Determine whether we should go to a zoomed state or not
      const shouldZoom = forceZoom || !this.zoomedIn;
      const target =
        this.$root.querySelector("[data-graph-target=viz]") || this.$root;
      const axis = target.dataset.zoomAxis;
      const start = +target.dataset.zoomStart;
      // We currently hardcode the total range
      const totalRange = axis === "x" ? 1000 : 500;
      const zoomedRange = +target.dataset.zoomRange;
      const fn = shouldZoom
        ? (pos) => ((pos - start) / zoomedRange) * totalRange
        : (pos) => (pos / totalRange) * zoomedRange + start;

      const attributes = [axis, `c${axis}`, `${axis}1`, `${axis}2`];
      for (const attr of attributes) {
        for (const el of target.querySelectorAll(
          `[${attr}]:not([data-zoom-target=ignore])`,
        )) {
          const newPos = fn(+el.getAttribute(attr));
          el.setAttribute(attr, newPos);
          if (el.hasAttribute("data-zoom-hide")) {
            +el.getAttribute("data-zoom-hide") < newPos
              ? el.style.setProperty("display", "none")
              : el.style.removeProperty("display");
          }
        }
      }

      // This currently only works for the y-axis
      for (const el of target.querySelectorAll("[data-zoom-target=scale]")) {
        el.style.setProperty(
          "--scale-ratio",
          shouldZoom ? totalRange / zoomedRange : 1,
        );
      }

      this.zoomedIn = shouldZoom;
    },
  };
}
